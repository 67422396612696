import React, { useEffect, useLayoutEffect, useState } from 'react';
import { GrafanaTheme2 } from '@grafana/data';
import { useAppState } from 'app-context/AppStateContext';
import { MultiSelect, useStyles2, Icon } from '@grafana/ui';
import { css } from '@emotion/css';
import { getMetricsOptions } from 'utils/helpers';

interface FiltersMetricsListProps {
  data: any;
}

const METRIC_LIMIT_SELECTION = 2;

export const FiltersMetricsList: React.FunctionComponent<FiltersMetricsListProps> = ({ data }) => {
  const { dispatch, state } = useAppState();
  const { selectedMetrics, dico, metricsOptions, selectedDashboards, metricsToDisplay } = state;
  const styles = useStyles2(getStyles);
  const [warningMetricMessage, setWarningMetricMessage] = useState('');

  const {
    anomalyScore,
    onOffState,
    rotationSpeed,
    vibrationSeverity,
    warningMessageMetrics,
    acousticProfileMax,
    acousticProfileMin,
    rotatingProfile,
    nonRotatingProfile,
    openClosedState,
    valve,
  } = dico;

  const VALVE_QUERIES = [acousticProfileMax, acousticProfileMin, openClosedState];
  const SIGNATURE_ANOMALY_QUERIES = [anomalyScore, onOffState, rotationSpeed, vibrationSeverity, ...VALVE_QUERIES];
  const ROTATING_QUERIES = [anomalyScore, onOffState, rotationSpeed];
  const TR_rotating = rotatingProfile;
  const TR_nonRotating = nonRotatingProfile;
  const TR_valve = valve;

  const onMetricChange = (value: any[]) => {
    if (!value) {
      dispatch({ type: 'SET_SELECTED_METRICS', payload: [] });
    }

    if (value.length > METRIC_LIMIT_SELECTION) {
      return;
    }
    dispatch({ type: 'SET_SELECTED_METRICS', payload: value });
  };

  useEffect(() => {
    if (data?.request?.targets) {
      let newMetrics = getMetricsOptions(dico, data.request.targets);
      dispatch({ type: 'SET_METRICS_OPTIONS', payload: newMetrics });
      dispatch({ type: 'SET_METRICS_TO_DISPLAY', payload: newMetrics });
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  // Warn user that only data from the last session is retrieved
  useEffect(() => {
    if (selectedMetrics.length) {
      const signAnomalyMetrics = selectedMetrics.filter(
        (metric) => SIGNATURE_ANOMALY_QUERIES.indexOf(metric.label) > -1
      );
      if (signAnomalyMetrics.length) {
        setWarningMetricMessage(`${signAnomalyMetrics.map((metric) => metric.label)} : ${warningMessageMetrics}`);
        return;
      }
      setWarningMetricMessage('');
      return;
    }
    setWarningMetricMessage('');
  }, [selectedMetrics]); // eslint-disable-line react-hooks/exhaustive-deps

  // Render metrics conditionally, depending on dashboards profile
  useLayoutEffect(() => {
    if (!selectedDashboards.length) {
      dispatch({ type: 'SET_METRICS_TO_DISPLAY', payload: metricsOptions });
    }
    if (selectedDashboards?.length && metricsOptions?.length) {
      // Rotating / Non-rotating
      if (
        selectedDashboards.every(
          (selected) => selected.description === TR_rotating || selected.description === TR_nonRotating
        )
      ) {
        const rotatingMetrics = metricsOptions.filter((metric) => VALVE_QUERIES.indexOf(metric.label!) === -1);
        dispatch({ type: 'SET_METRICS_TO_DISPLAY', payload: rotatingMetrics });

        if (selectedMetrics.length) {
          const valveQueries = selectedMetrics.filter(
            (selectedMetric) => VALVE_QUERIES.indexOf(selectedMetric.label!) === -1
          );
          onMetricChange(valveQueries);
        }
        return;
      }
      // Valve
      if (selectedDashboards.every((selected) => selected.description === TR_valve)) {
        const valveMetrics = metricsOptions.filter((metric) => ROTATING_QUERIES.indexOf(metric.label!) === -1);
        dispatch({ type: 'SET_METRICS_TO_DISPLAY', payload: valveMetrics });
        if (selectedMetrics.length) {
          const rotatingQueries = selectedMetrics.filter(
            (selectedMetric) => ROTATING_QUERIES.indexOf(selectedMetric.label!) === -1
          );
          onMetricChange(rotatingQueries);
        }
        return;
      }
      // Mixed
      const mixedProfileMetrics = metricsOptions.filter(
        (metric) => ROTATING_QUERIES.indexOf(metric.label!) === -1 && VALVE_QUERIES.indexOf(metric.label!) === -1
      );
      dispatch({ type: 'SET_METRICS_TO_DISPLAY', payload: mixedProfileMetrics });
      if (selectedMetrics.length) {
        const mixedQueries = selectedMetrics.filter(
          (selectedMetric) =>
            ROTATING_QUERIES.indexOf(selectedMetric.label!) === -1 &&
            VALVE_QUERIES.indexOf(selectedMetric.label!) === -1
        );
        onMetricChange(mixedQueries);
      }
    }
  }, [selectedDashboards]); // eslint-disable-line react-hooks/exhaustive-deps

  const countSelectedMetric = React.useMemo(() => selectedMetrics?.length, [selectedMetrics]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.title}>
        {dico.metrics?.toUpperCase()} {countSelectedMetric} / {METRIC_LIMIT_SELECTION}
      </div>
      <MultiSelect
        options={metricsToDisplay}
        onChange={(v) => onMetricChange(v)}
        value={selectedMetrics}
        key={`key__metrics`} // !! this key allows setting a null value to the select component !!
        isClearable
        isSearchable
        placeholder={dico.selectMetrics}
        maxMenuHeight={200}
      />
      {warningMetricMessage.length !== 0 && (
        <div className={styles.warningContainer}>
          <Icon name={'info-circle'} />
          {warningMetricMessage?.toUpperCase()}
        </div>
      )}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    mainContainer: css`
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: flex-start;
      margin: 2px;
      min-width: 277px;
      padding: 4px 4px;
      width: 100%;
    `,
    title: css`
      color: ${theme.colors.text.primary};
      font-weight: 500;
      font-size: 16px;
    `,
    warningContainer: css`
      font-size: 12px;
      padding: 8px 0 0 8px;
      font-weight: 500;
      color: #8ab7bb;
    `,
  };
};
