import React from 'react';
import { useAppState } from 'app-context/AppStateContext';
import { LengthUnits, TemperatureUnits, UnitTypes } from 'app-context/types';
import { RadioButtonGroup, HorizontalGroup, Label } from '@grafana/ui';
import { CELSIUS, FAHRENHEIT, IN_S, MM_S } from 'utils/constants';
import { css } from '@emotion/css';

interface UnitOptionsProps {
  setUnits: (
    value: React.SetStateAction<{
      temperature: TemperatureUnits;
      length: LengthUnits;
    }>
  ) => void;
  units: {
    temperature: TemperatureUnits;
    length: LengthUnits;
  };
  layout: any;
}

// TEMPERATURE AND LENGTH UNITS SUPPORT

export const UnitOptions: React.FunctionComponent<UnitOptionsProps> = ({ setUnits, units, layout }) => {
  const { state } = useAppState();
  const { dico, selectedDashboards, selectedMetrics } = state;
  const { vibrationSeverity, surfaceTemperature, lengthUnit, temperatureUnit, velocity, rotationSpeed } = dico;

  const LENGTH_UNIT_OPTIONS = [
    { label: MM_S, value: LengthUnits.meter },
    { label: IN_S, value: LengthUnits.inch },
  ];
  const TEMPERATURE_UNIT_OPTIONS = [
    { label: FAHRENHEIT, value: TemperatureUnits.fahrenheit },
    { label: CELSIUS, value: TemperatureUnits.celsius },
  ];

  const isTemperatureMetric = selectedMetrics?.filter((data) => data.label.includes(surfaceTemperature));
  const isVelocityMetric = selectedMetrics?.filter(
    (data) => data.label.includes(vibrationSeverity) || (data.label.includes(velocity) && data.label !== rotationSpeed)
  );

  const onChangeUnit = (e: string | undefined, unitType: string) => {
    if (!e) {
      return;
    }
    if (unitType === UnitTypes.length) {
      setUnits({ ...units, length: e! as LengthUnits });
      selectedMetrics.forEach((metric: { label: string }, index: number) => {
        const isVelocityMetric =
          metric.label.includes(vibrationSeverity) ||
          (metric.label.includes(velocity) && metric.label !== rotationSpeed);

        if (isVelocityMetric) {
          switch (index) {
            case 0:
              layout.yaxis.title.text = e === LengthUnits.meter ? MM_S : IN_S;
              break;
            case 1:
              layout.yaxis2.title.text = e === LengthUnits.meter ? MM_S : IN_S;
              break;
            default:
              break;
          }
        }
      });
    }

    if (unitType === UnitTypes.temperature) {
      setUnits({ ...units, temperature: e! as TemperatureUnits });
      selectedMetrics.forEach((metric: { label: string }, index: number) => {
        const isTemperatureMetric = metric.label.includes(surfaceTemperature);

        if (isTemperatureMetric) {
          switch (index) {
            case 0:
              layout.yaxis.title.text = e === TemperatureUnits.celsius ? CELSIUS : FAHRENHEIT;
              break;
            case 1:
              layout.yaxis2.title.text = e === TemperatureUnits.celsius ? CELSIUS : FAHRENHEIT;
              break;
            default:
              break;
          }
        }
      });
    }
  };

  return (
    <div style={{ display: 'flex', width: '100%', padding: '18px 29px' }}>
      <HorizontalGroup spacing="md" align="center" justify="flex-start" wrap>
        {isVelocityMetric.length !== 0 && selectedDashboards.length !== 0 && (
          <>
            <Label
              className={css`
                margin-right: 8px;
              `}
            >
              {lengthUnit}
            </Label>
            <RadioButtonGroup
              options={LENGTH_UNIT_OPTIONS}
              size={'sm'}
              onChange={(e) => onChangeUnit(e, UnitTypes.length)}
              value={units.length}
            />
          </>
        )}
        {isTemperatureMetric.length !== 0 && selectedDashboards.length !== 0 && (
          <>
            <Label
              className={css`
                margin-right: 8px;
              `}
            >
              {temperatureUnit}
            </Label>
            <RadioButtonGroup
              options={TEMPERATURE_UNIT_OPTIONS}
              size={'sm'}
              onChange={(e) => onChangeUnit(e, UnitTypes.temperature)}
              value={units.temperature}
            />
          </>
        )}
      </HorizontalGroup>
    </div>
  );
};
