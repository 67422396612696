import React from 'react';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, Icon } from '@grafana/ui';
import { FiltersFoldersList, FiltersDashboardsList, FiltersMetricsList, FiltersTagsList } from './';
import { useAppState } from 'app-context/AppStateContext';

interface FiltersFoldableSectionProps {
  data: any;
}

export const FiltersFoldableSection: React.FunctionComponent<FiltersFoldableSectionProps> = ({ data }) => {
  const { state } = useAppState();
  const { dico } = state;
  const styles = useStyles2(getStyles);

  const DASHBOARD_FILTERS = dico.dashboardFilters?.toUpperCase();

  return (
    <div className={styles.hiddenSection}>
      <div className={styles.filters}>
        <div className={styles.secondarySectionTitle}>
          <span className={styles.iconMargin}>
            <Icon name={'filter'} />
          </span>
          {DASHBOARD_FILTERS}
        </div>
        <div className={styles.firstHorizontalSection}>
          <FiltersFoldersList />
          <FiltersTagsList />
        </div>
      </div>
      <div className={styles.secondHorizontalSection}>
        <FiltersDashboardsList />
        <FiltersMetricsList data={data} />
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    filters: css`
      display: flex;
      flex-direction: column;
      background-color: ${theme.colors.background.secondary};
      flex: 2;
      padding: 16px;
    `,
    hiddenSection: css`
      display: flex;
      width: 100%;
      flex-direction: column;
      color: ${theme.colors.text.primary};
      padding: 16px 32px;
    `,
    firstHorizontalSection: css`
      display: flex;
      flex-wrap: wrap;
    `,
    secondHorizontalSection: css`
      display: flex;
      flex: 4;
      padding: 16px 24px;
      flex-wrap: wrap;
    `,
    secondarySectionTitle: css`
      margin-bottom: 8px;
      font-weight: 500;
    `,
    iconMargin: css`
      margin-right: 6px;
    `,
  };
};
