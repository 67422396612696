import React, { useState, useEffect } from 'react';
import { css } from '@emotion/css';
import { MultiSelect, useStyles2, Icon, IconName } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { PREFIX_MACHINE_FILTER } from 'utils/constants';
import { fromSelectedFolders, getOtherTagsOptions, getTagsFromDashboards } from 'utils/helpers';
import { TagType } from 'app-context/types';

interface FiltersMultiSelectTagsProps {
  iconName: IconName;
  options: any[];
  placeholder: string;
  selectLabel: string;
  id: TagType;
}

export const FiltersMultiSelectTags: React.FunctionComponent<FiltersMultiSelectTagsProps> = ({
  iconName,
  options,
  placeholder,
  selectLabel,
  id,
}) => {
  const { state, dispatch } = useAppState();
  const { selectedFolders, dashboardOptions, selectedTags, tagsOptions } = state;
  const [selectOptions, setSelectOptions] = useState([] as any);
  const styles = useStyles2(getStyles);

  const onSelectChange = (value: any) => {
    dispatch({ type: 'SET_SELECTED_DASHBOARDS', payload: [] });

    if (id === TagType.machine) {
      dispatch({
        type: 'SET_SELECTED_TAGS',
        payload: {
          machines: value.length ? value : [],
          otherTags: [],
        },
      });
      return;
    }

    dispatch({
      type: 'SET_SELECTED_TAGS',
      payload: {
        machines: selectedTags.machines,
        otherTags: id === TagType.other ? value : selectedTags.otherTags,
      },
    });
  };

  useEffect(() => {
    if (selectedFolders?.length) {
      const filteredDashboards = fromSelectedFolders(dashboardOptions, selectedFolders);
      const filterbyTag = getTagsFromDashboards(filteredDashboards);

      id === TagType.machine &&
        setSelectOptions(
          filterbyTag.machines?.map(
            (machine: { label: string; value: string }) =>
              (machine = {
                label: machine?.label?.replace(PREFIX_MACHINE_FILTER, ''),
                value: machine?.value?.replace(PREFIX_MACHINE_FILTER, ''),
              })
          )
        );

      id === TagType.other && setSelectOptions(filterbyTag.otherTags);
      return;
    }

    setSelectOptions(options);
    dispatch({
      type: 'SET_SELECTED_TAGS',
      payload: {
        machines: [],
        otherTags: [],
      },
    });
  }, [selectedFolders]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedTags?.machines?.length) {
      if (id === TagType.other) {
        const otherTags = getOtherTagsOptions(dashboardOptions, selectedTags);
        setSelectOptions(otherTags);
      }
    } else if (!selectedFolders.length) {
      setSelectOptions(options);
    } else {
      const filteredDashboards = fromSelectedFolders(dashboardOptions, selectedFolders);
      const filterbyTag = getTagsFromDashboards(filteredDashboards);

      id === TagType.machine &&
        setSelectOptions(
          filterbyTag.machines?.map(
            (machine: { label: string; value: string }) =>
              (machine = {
                label: machine?.label?.replace(PREFIX_MACHINE_FILTER, ''),
                value: machine?.value?.replace(PREFIX_MACHINE_FILTER, ''),
              })
          )
        );

      id === TagType.other && setSelectOptions(filterbyTag.otherTags);
      return;
    }
  }, [selectedTags.machines]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectedFolders?.length) {
      if (id === TagType.other) {
        setSelectOptions(tagsOptions.otherTags);
        return;
      }
      if (id === TagType.machine) {
        setSelectOptions(tagsOptions.machines);
        return;
      }
    }
  }, [tagsOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.mainContainer}>
      <div>{selectLabel}</div>
      <MultiSelect
        options={selectOptions}
        value={id === TagType.machine ? selectedTags?.machines : selectedTags.otherTags}
        onChange={(v) => onSelectChange(v)}
        maxVisibleValues={5}
        isClearable
        isSearchable
        placeholder={placeholder}
        maxMenuHeight={200}
        prefix={<Icon name={iconName} />}
      />
    </div>
  );
};

export const getStyles = () => {
  return {
    mainContainer: css`
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      min-width: 277px;
      width: 100%;
      margin-bottom: 12px;
      margin: 2px;
    `,
  };
};
