import React from 'react';
import { useAppState } from 'app-context/AppStateContext';
import { Spinner, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { useMetricPlot } from 'hooks/useMetricPlot';
import { css } from '@emotion/css';
// @ts-ignore
// Could not find a declaration file for module 'plotly.js-cartesian-dist'.
import Plotly from 'plotly.js-cartesian-dist';
// @ts-ignore
// Could not find a declaration file for module 'react-plotly.js'.
import * as csDictionary from 'plotly.js/lib/locales/fr.js';
// @ts-ignore
// Could not find a declaration file for module 'react-plotly.js/factory'.
import createPlotlyComponent from 'react-plotly.js/factory';
import { metricPlotLayout } from './metricPlotLayout';
import { updatePlotLayout } from 'utils/helpers';
import { UnitOptions } from 'components/UnitOptions';
import { ButtonsCSVExport } from 'components/ButtonsCSVExport';

Plotly.register(csDictionary);
const MetricsPlots = createPlotlyComponent(Plotly);

interface MetricPlotProps {}

export const MetricPlot: React.FunctionComponent<MetricPlotProps> = () => {
  const { onHover, dataPlot, isDataLoading, units, setUnits, fileTitle } = useMetricPlot();

  const { state } = useAppState();
  const { lang, theme } = state;

  const styles = useStyles2(getStyles);

  updatePlotLayout(theme, metricPlotLayout);

  return (
    <>
      <div style={{ position: 'relative' }}>
        {isDataLoading && (
          <div className={styles.loaderContainer}>
            <Spinner size={55} />
          </div>
        )}
        <div>
          {!isDataLoading && (
            <div className={styles.buttonContainer}>
              <div>
                <UnitOptions units={units} setUnits={setUnits} layout={metricPlotLayout} />
              </div>
              <div>
                <ButtonsCSVExport dataPlot={dataPlot} />
              </div>
            </div>
          )}
          <MetricsPlots
            id="Metrics compared"
            divId="plotContainer"
            data={dataPlot}
            onHover={onHover}
            layout={metricPlotLayout}
            style={{ margin: '15px', padding: '10px', height: '610px' }}
            config={{
              displaylogo: false,
              responsive: true,
              locale: lang.toLowerCase(),
              modeBarButtonsToRemove: ['select2d', 'lasso2d', 'zoomIn2d', 'zoomOut2d'],
              toImageButtonOptions: {
                format: 'png',
                filename: `${fileTitle}`,
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    loaderContainer: css`
      height: 640px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 1;
      background-color: ${theme.isDark ? '#2d2d2ecc' : '#fbfcfecc'};
    `,
    buttonContainer: css`
      display: flex;
      width: 100%;
      justify-content: space-between;
    `,
  };
};
