import React from 'react';
import { css } from '@emotion/css';
import { MetricPlot } from 'MetricPlot';
import { useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';

interface PlotsSectionProps {}

export const PlotsSection: React.FunctionComponent<PlotsSectionProps> = () => {
  const styles = useStyles2(getStyles);

  return (
    <>
      <div className={styles.mainContainer}>
        <MetricPlot />
      </div>
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    mainContainer: css`
      display: flex;
      width: 100%;
      flex-direction: column;
      background-color: ${theme.isDark ? '#1f1f20' : '#fbfcfe'};
      border-right: 12px solid ${theme.isDark ? '#1f1f20' : theme.colors.background.canvas};
      border-left: 12px solid ${theme.isDark ? '#1f1f20' : theme.colors.background.canvas};
      border-bottom: 12px solid ${theme.isDark ? '#1f1f20' : theme.colors.background.canvas};
    `,
  };
};
