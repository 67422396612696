import { getBackendSrv } from '@grafana/runtime';

export const fetchDashboardSessionId = async (macAddress: string, mysqlUid: number) => {
  const requestIdZones = `SELECT IFNULL(max(id), '') FROM ai_session WHERE mac_address='${macAddress}' and status='READY'`;
  const queryResult = await getBackendSrv()
    .post(`api/ds/query`, {
      queries: [
        {
          datasource: {
            type: 'mysql',
            uid: mysqlUid,
          },
          format: 'table',
          rawSql: requestIdZones,
          refId: 'tempvar',
        },
      ],
    })
    .catch((err: any) => {
      console.log(err);
    });
  const results = queryResult.results.tempvar.frames;
  if (results.length) {
    const resultsArrays = results[0].data.values;
    const finalresult = resultsArrays[0].map(() => new Array(resultsArrays.length));
    for (const [indexArray, array] of resultsArrays.entries()) {
      for (const [indexValue, value] of array.entries()) {
        finalresult[indexValue][indexArray] = value;
      }
    }
    return finalresult[0][0];
  } else {
    return [];
  }
};
