export let metricPlotLayout = {
  height: 610,
  plot_bgcolor: 'transparent',
  paper_bgcolor: 'transparent',
  margin: {
    l: 50,
    b: 50,
    t: 30,
    r: 50,
  },
  xaxis: {
    type: 'date',
    gridcolor: 'white',
    zeroline: false,
    autorange: true,
    range: [] as any,
    fixedrange: false, // disables zoom
  },
  yaxis: {
    anchor: 'x',
    autorange: true,
    color: '',
    fixedrange: false, // disables zoom
    gridcolor: 'white',
    zeroline: false,
    title: {
      text: '',
    },
  },
  yaxis2: {
    anchor: 'x',
    autorange: true,
    color: '',
    fixedrange: false,
    zeroline: false,
    gridcolor: 'white',
    title: {
      text: '',
    },
  },
  shapes: [] as any,
  annotations: [] as any,
  uirevision: 'true' as any,
  hoverlabel: {
    bgcolor: '',
    font: {
      color: '',
    },
  },
  hovermode: 'x unified',
  showlegend: true,
  legend: {
    groupclick: 'toggleitem',
    grouptitlefont: {
      color: '',
    },
    font: {
      color: '',
    },
  },
  grid: { rows: 2, columns: 1, pattern: 'coupled' },
};
