import React from 'react';
import { css } from '@emotion/css';
import { FilterPill, HorizontalGroup } from '@grafana/ui';
import { DashboardsProps } from 'app-context/types';
import { TranslationProps } from 'app-context/translations';

interface FiltersSelectionSummaryProps {
  selectedMetrics: any[];
  selectedDashboards: DashboardsProps[];
  dico: TranslationProps;
}

export const FiltersSelectionSummary: React.FunctionComponent<FiltersSelectionSummaryProps> = ({
  selectedMetrics,
  selectedDashboards,
  dico,
}) => {
  const selectedDashboardsLabel = `${selectedDashboards.length} ${
    selectedDashboards.length > 1 ? dico.dashboards : dico.dashboard
  }`;
  const selectedDashboardsIcon = selectedDashboards.length > 0 ? 'check' : 'times';

  return (
    <div
      className={css`
        display: flex;
        cursor: default;
      `}
    >
      <HorizontalGroup>
        {selectedMetrics?.length !== 0 &&
          selectedMetrics.map((metric) => (
            <span key={metric.value}>
              <FilterPill label={metric.label} icon={'chart-line'} selected onClick={(e) => null} />
            </span>
          ))}
        {selectedMetrics?.length === 0 && (
          <span>
            <FilterPill label={`0 ${dico.metric}`} icon={'times'} selected onClick={(e) => null} />
          </span>
        )}
        {
          <span>
            <FilterPill label={selectedDashboardsLabel} icon={selectedDashboardsIcon} selected onClick={(e) => null} />
          </span>
        }
      </HorizontalGroup>
    </div>
  );
};
