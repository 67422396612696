import React, { useEffect, useMemo, useState } from 'react';
import { useAppState } from 'app-context/AppStateContext';
import { DataPlotProps, LengthUnits, TemperatureUnits } from 'app-context/types';
import { dateTimeFormatISO } from '@grafana/data';
// @ts-ignore
// Could not find a declaration file for module 'plotly.js-cartesian-dist'.
import Plotly from 'plotly.js-cartesian-dist';
// @ts-ignore
// Could not find a declaration file for module 'react-plotly.js'.
import * as csDictionary from 'plotly.js/lib/locales/fr.js';
// @ts-ignore
// Could not find a declaration file for module 'react-plotly.js/factory'.
import createPlotlyComponent from 'react-plotly.js/factory';

Plotly.register(csDictionary);

import { metricPlotLayout } from '../metricPlotLayout';
import { getBackendSrv, getTemplateSrv } from '@grafana/runtime';
import {
  getMetricUnit,
  getFormatedXYZQuery,
  getDataPlot,
  getDashboardName,
  LightenDarkenColor,
  getDashboardOrientation,
  getInterpolatedOrientation,
  groupByMacAddress,
  getDashboardIndex,
  updateGraphTitle,
  updatePlotLayout,
  resetZoom,
  interpolateTimeFilter,
} from 'utils/helpers';
import { CELSIUS, COLORS_LINES, FAHRENHEIT, IN_S, MM_S } from 'utils/constants';
import { fetchDashboardSessionId } from 'utils/fetchDashboardSessionId';

Plotly.register(csDictionary);

export const useMetricPlot = () => {
  const { state } = useAppState();
  const { dico, influx, mysql, panelData, selectedDashboards, selectedMetrics, theme, timeRange, timeZone } = state;

  const [dataPlot, setDataPlot] = useState<DataPlotProps[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [units, setUnits] = useState({ temperature: TemperatureUnits.celsius, length: LengthUnits.meter });

  const macAddresses = useMemo(() => selectedDashboards?.map((dash) => dash.macAddress), [selectedDashboards]);
  const macAddressesJoined = useMemo(
    () => selectedDashboards?.map((dash) => dash.macAddress).join('|'),
    [selectedDashboards]
  );

  const TIMEZONE = React.useMemo(() => {
    let timeZoneToQuery = timeZone;
    if (timeZone === 'browser' || timeZone === 'utc') {
      timeZoneToQuery = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return timeZoneToQuery;
  }, [timeZone]);

  // Handle orientation
  const VIB_KURTOSIS = dico.vibrationsKurtosis;
  const VIB_PEAK = dico.vibrationsPeakAcc;
  const VIB_RMS_ACC = dico.vibrationsRmsAcc;
  const VIB_RMS_VEL = dico.vibrationsRmsVel;
  const XYZ_QUERIES = [VIB_KURTOSIS, VIB_PEAK, VIB_RMS_ACC, VIB_RMS_VEL];

  // handle signature_anomaly query (max id session injection)
  const ANOMALY_SCORE = dico.anomalyScore;
  const ON_OFF = dico.onOffState;
  const ROTATION_SPEED = dico.rotationSpeed;
  const VIB_SEVERITY = dico.vibrationSeverity;
  const OPEN_CLOSED = dico.openClosedState;
  const ACOUSTIC_MIN = dico.acousticProfileMin;
  const ACOUSTIC_MAX = dico.acousticProfileMax;
  const SIGNATURE_ANOMALY_QUERIES = [
    ANOMALY_SCORE,
    ON_OFF,
    ROTATION_SPEED,
    VIB_SEVERITY,
    OPEN_CLOSED,
    ACOUSTIC_MIN,
    ACOUSTIC_MAX,
  ];
  const SURFACE_TEMP = dico.surfaceTemperature;

  updatePlotLayout(theme, metricPlotLayout);

  const fileTitle = `Comparison-${dateTimeFormatISO(new Date())}`;

  const sendQuery = async (query: string, finalQueries: any) => {
    if (!query) {
      return;
    }

    setIsDataLoading(true);
    await getBackendSrv()
      .get(`api/datasources/proxy/uid/${influx?.uid}/query?db=${influx.name}&q=${query}&epoch=ms`)
      .then((result) => {
        metricPlotLayout.annotations = [];
        if (!result?.results?.length) {
          return;
        }

        const allDataPlot = [] as any;
        const XYZResults = [];

        // Handle basic queries (XYZ queries not concerned)
        for (const metricResult of result.results) {
          if (!metricResult?.series?.length) {
            continue;
          }

          for (const serie of metricResult?.series) {
            const dashboardName = getDashboardName(selectedDashboards, serie?.tags?.device);
            const metricName = serie.columns.length && serie?.columns[1];

            // Treat XYZ results separately
            if (XYZ_QUERIES.findIndex((query) => metricName.includes(query)) > -1) {
              XYZResults.push(serie);
              continue;
            }

            const metricSelectionOrder = selectedMetrics.findIndex((metric) => metricName.includes(metric.label));
            const dashboardIndex = getDashboardIndex(selectedDashboards, serie?.tags?.device);
            const newDataPlot = getDataPlot(
              serie,
              metricName,
              dashboardName,
              metricSelectionOrder === 1 ? 'y2' : 'y',
              COLORS_LINES[dashboardIndex],
              dico,
              units,
              TIMEZONE
            );
            allDataPlot.push(newDataPlot);
          }
        }

        /** Handle XYZ queries */
        if (XYZResults.length) {
          // Group by mac address and by metrics to enable smart lines coloration
          const macAddressResults = groupByMacAddress(XYZResults);

          for (const resultQuery of macAddressResults) {
            const { macAddress, queries } = resultQuery;

            const dashboardIndex = getDashboardIndex(selectedDashboards, macAddress);
            const dashboardName = getDashboardName(selectedDashboards, macAddress);
            const dashboardOrientation = getDashboardOrientation(selectedDashboards, macAddress);

            for (const query of queries) {
              for (const metricQuery of query.query) {
                const metricName = metricQuery?.columns[1];
                const metricSelectionIndex = selectedMetrics.findIndex((metric) => metricName.includes(metric.label));

                const metricColor = LightenDarkenColor(COLORS_LINES[dashboardIndex], 40);
                const interpolatedMetric = getInterpolatedOrientation(metricName, dashboardOrientation);
                const newDataPlot = getDataPlot(
                  metricQuery,
                  interpolatedMetric,
                  dashboardName,
                  metricSelectionIndex === 1 ? 'y2' : 'y',
                  metricColor,
                  dico,
                  units,
                  TIMEZONE
                );
                allDataPlot.push(newDataPlot);
              }
            }
          }
        }
        const plotTitles = [];
        let nbDataEmpty = 0;
        if (selectedMetrics.length === 2) {
          let gridRows = 2;
          for (const [indexMetric, metric] of selectedMetrics.entries()) {
            const index = allDataPlot.findIndex((data: { name: string }) => data.name?.startsWith(metric?.label));
            if (index === -1) {
              gridRows = 1;
              nbDataEmpty += 1;
            } else {
              const title = updateGraphTitle(theme, metric?.label, indexMetric === 1 && !nbDataEmpty ? 0.5 : 1.05);
              plotTitles.push(title);
            }
          }
          metricPlotLayout.grid.rows = gridRows;
        } else {
          metricPlotLayout.grid.rows = 1;
          const title = updateGraphTitle(theme, selectedMetrics[0].label, 1.05);
          plotTitles.push(title);
        }

        setDataPlot(allDataPlot);
        setIsDataLoading(false);

        if (!allDataPlot.length) {
          return;
        }
        // Add subplot title (as annotation)
        metricPlotLayout.annotations = plotTitles;
        resetZoom(metricPlotLayout);
      })
      .catch((err) => {
        console.log(err);
        setIsDataLoading(false);
      });
  };

  const onHover = (data: { xvals: any[] }) => {
    /**  Workaround to display labels accross x axes on hover.
     * Use of the plotly.js function Plotly.Fx.hover
     */
    const subplots = selectedMetrics?.length === 2 ? ['xy', 'xy2'] : ['xy'];
    if (data.xvals) {
      Plotly.Fx.hover('plotContainer', { xval: data.xvals[0] }, subplots);
    }
  };

  /** METRIC SELECTION */
  useEffect(() => {
    if (!selectedDashboards?.length || !selectedMetrics?.length) {
      metricPlotLayout.annotations = [];
      metricPlotLayout.yaxis.title.text = '';
      metricPlotLayout.yaxis.autorange = true;
      resetZoom(metricPlotLayout);
      setDataPlot([]);
      return;
    }

    if (panelData && selectedDashboards.length && selectedMetrics.length) {
      let asyncQueries = [] as any;
      let interpolatedQueries = [] as any;

      const TARGETS = panelData?.request?.targets;
      const SCOPED_VARS = panelData?.request?.scopedVars;

      for (const metric of selectedMetrics) {
        /** Handle id session for each selected dashboard */
        if (SIGNATURE_ANOMALY_QUERIES.indexOf(metric.label) > -1) {
          const queriesPromises = macAddresses.map(async (macAddress: string) => {
            const sessionId = await fetchDashboardSessionId(macAddress, mysql.uid);
            const targetsIndex = panelData?.request?.targets?.findIndex(
              (target: { alias: string }) => target.alias === metric.label
            );
            if (targetsIndex !== -1) {
              let signAnomalyQuery = panelData?.request?.targets[targetsIndex].query
                .replace('~ /^$beacons_selection$/', `'${macAddress}'`)
                .replace('$session_id', `${sessionId}`);
              const interpolatedVars = getTemplateSrv().replace(signAnomalyQuery, panelData.request.scopedVars);
              return { ...panelData?.request?.targets[targetsIndex], alias: metric.label, query: interpolatedVars };
            }
            return null;
          });
          asyncQueries.push(...queriesPromises);
        }
        /**
         * Handle beacons' orientation
         */
        if (XYZ_QUERIES.indexOf(metric.label) > -1) {
          const modifiedQueries = getFormatedXYZQuery(TARGETS, SCOPED_VARS, metric.label, macAddressesJoined);
          modifiedQueries.length && interpolatedQueries.push(modifiedQueries);
        }

        const targetsIndex = panelData?.request?.targets?.findIndex(
          (target: { alias: string }) => target.alias === metric.label
        );

        /** Interpolate template variables only if metric is not in SIGNATURE_ANOMALY_QUERIES
         * because this has been already done, mac address by mac address (with their session id) */

        if (targetsIndex !== -1 && SIGNATURE_ANOMALY_QUERIES.indexOf(metric.label) === -1) {
          const metricQuery = panelData.request.targets[targetsIndex].query.replace(
            '^$beacons_selection$',
            `${macAddressesJoined}`
          );
          const interpolatedVars = getTemplateSrv().replace(metricQuery, panelData.request.scopedVars);
          interpolatedQueries.push({
            ...panelData?.request?.targets[targetsIndex],
            alias: metric.label,
            query: interpolatedVars,
          });
        }
      }

      Promise.all(asyncQueries)
        .then((results) => {
          const finalQueries = [...results, ...interpolatedQueries];
          let metricToSend = finalQueries
            .flat()
            .map((metric) => interpolateTimeFilter(timeRange, metric.query, TIMEZONE))
            .join('%3B'); // the ; is removed when query is send, need this to keep it
          sendQuery(metricToSend, finalQueries);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [selectedDashboards, selectedMetrics, panelData, units]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // update metric unit (y axis title)
    if (selectedMetrics?.length && selectedDashboards?.length) {
      selectedMetrics.forEach((metric: { label: string }, index: number) => {
        const isTemperatureMetric = metric.label.includes(SURFACE_TEMP);
        const isLengthMetric =
          metric.label.includes(VIB_SEVERITY) ||
          (metric.label.includes(dico.velocity) && metric.label !== ROTATION_SPEED);

        const unitMetric = getMetricUnit(metric.label?.toLowerCase(), dico).unit;
        if (index === 0) {
          if (isTemperatureMetric) {
            metricPlotLayout.yaxis.title.text = units.temperature === TemperatureUnits.celsius ? CELSIUS : FAHRENHEIT;
          } else if (isLengthMetric) {
            metricPlotLayout.yaxis.title.text = units.length === LengthUnits.meter ? MM_S : IN_S;
          } else {
            metricPlotLayout.yaxis.title.text = unitMetric;
          }
        }

        if (index === 1) {
          if (isTemperatureMetric) {
            metricPlotLayout.yaxis2.title.text = units.temperature === TemperatureUnits.celsius ? CELSIUS : FAHRENHEIT;
          } else if (isLengthMetric) {
            metricPlotLayout.yaxis2.title.text = units.length === LengthUnits.meter ? MM_S : IN_S;
          } else {
            metricPlotLayout.yaxis2.title.text = unitMetric;
          }
        }
      });
    }
  }, [dataPlot]); // eslint-disable-line react-hooks/exhaustive-deps

  /** Reset zoom on timerange, danshboards and metrics selection */
  useEffect(() => {
    resetZoom(metricPlotLayout);
  }, [selectedMetrics, selectedDashboards, timeRange]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    onHover,
    setDataPlot,
    dataPlot,
    isDataLoading,
    setIsDataLoading,
    units,
    setUnits,
    macAddresses,
    macAddressesJoined,
    fileTitle,
  };
};
