import { getBackendSrv } from '@grafana/runtime';
import { API_DASHBOARD_BY_UID, API_QUERY_DASHBOARD } from 'utils/api_url';
import { DashboardsProps } from 'app-context/types';

/**
 * Get all the dashboards from Grafana
 */

export const fetchDashboardsNames = async () => {
  let grafanaDashboards: DashboardsProps[] = [];

  await getBackendSrv()
    .get(`${API_QUERY_DASHBOARD}&tag=Machine`)
    // exclude dashboards that are folders (type dash-folder)
    // retrieve relevant dashboard data
    .then(async (result) => {
      if (!result) {
        return;
      }

      const onlyDashboards = result?.filter((dashboard: { type: string }) => dashboard.type === 'dash-db');
      grafanaDashboards = await Promise.all(
        onlyDashboards?.map(
          async (dashboard: {
            title: string;
            id: number;
            folderId: number;
            folderTitle: string;
            uid: string;
            tags: string[];
          }) => {
            const result = await getBackendSrv().get(`${API_DASHBOARD_BY_UID}${dashboard.uid}`);

            if (!result) {
              return;
            }

            if (!result?.dashboard?.panels?.length) {
              return;
            }

            // Get analysis_profile
            const incidentAdvisorIndex = result.dashboard.panels.findIndex(
              (panel: { type: string }) => panel.type === 'asystom-ad-ia'
            );
            let profile = '';
            if (incidentAdvisorIndex !== -1) {
              const indexProfile = result.dashboard.panels[incidentAdvisorIndex].idCardElements.findIndex(
                (el: { field: string }) => el.field === 'analysis_profile'
              );
              if (indexProfile !== -1) {
                profile = result.dashboard.panels[incidentAdvisorIndex].idCardElements[indexProfile].value;
              }
            }

            if (!result?.dashboard?.templating?.list.length) {
              return;
            }
            // Get orientation variables
            const dashboardVariables = ['beacon_selection', 'X', 'Y', 'Z'];
            const indexes = dashboardVariables.map((name) =>
              result.dashboard.templating.list.findIndex((template: { name: string }) => template.name === name)
            );
            const [macAddressIndex, varXIndex, varYIndex, varZIndex] = indexes;
            let Xvar = '';
            let Yvar = '';
            let Zvar = '';
            let macAddress = '';

            if (macAddressIndex !== -1 && varXIndex !== -1 && varYIndex !== -1 && varZIndex !== -1) {
              macAddress = result.dashboard.templating.list[macAddressIndex].query;
              Xvar = result.dashboard.templating.list[varXIndex].query;
              Yvar = result.dashboard.templating.list[varYIndex].query;
              Zvar = result.dashboard.templating.list[varZIndex].query;
            }

            return {
              title: dashboard.title,
              id: dashboard.id,
              folderId: dashboard.folderId ? dashboard.folderId : 0,
              folderTitle: dashboard.folderTitle ? dashboard.folderTitle : 'General',
              uid: dashboard.uid,
              tags: dashboard.tags,
              macAddress: macAddress,
              X: Xvar,
              Y: Yvar,
              Z: Zvar,
              description: profile,
            };
          }
        )
      );
    })
    .catch((error: any) => {
      console.log(error);
    });

  if (!Array.isArray(grafanaDashboards)) {
    return [];
  }

  const foldersAndDashboards = [];
  for (const dashboard of grafanaDashboards) {
    foldersAndDashboards.push({
      label: dashboard.title,
      value: dashboard.uid,
      folderId: dashboard.folderId,
      folderTitle: dashboard.folderTitle,
      tags: dashboard.tags,
      description: dashboard.description,
      macAddress: dashboard.macAddress,
      title: dashboard.title,
      id: dashboard.id,
      X: dashboard.X,
      Y: dashboard.Y,
      Z: dashboard.Z,
    });
  }

  return foldersAndDashboards;
};
