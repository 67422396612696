import { getTemplateSrv } from '@grafana/runtime';
import { DashboardsProps, TemperatureUnits, LengthUnits, GroupByMacAddressProps } from '../app-context/types';
import { TranslationProps } from 'app-context/translations';
import { GrafanaTheme2, SelectableValue, dateTimeFormatISO, TimeRange } from '@grafana/data';
import { FAHRENHEIT, IN_S, PREFIX_MACHINE_FILTER } from './constants';

export const getTemplateCurrentText = (text: string) => {
  const getTemplate = getTemplateSrv().getVariables() as any;
  const template = getTemplate && getTemplate.filter((template: { name: string }) => template.name === text);
  if (!template.length) {
    // NotificationError({ message: `Template ${text} not found` });
    return;
  }
  return template[0].current?.value;
};

export const getMetricUnit = (metricName: string, dico: TranslationProps) => {
  let metricUnit = { unit: '', decimals: 0 };

  switch (true) {
    case metricName === dico.battery?.toLowerCase(): {
      metricUnit.unit = 'V';
      metricUnit.decimals = 2;
      break;
    }

    case metricName === dico.vibrationSeverity?.toLowerCase() ||
      (metricName?.includes(dico.velocity?.toLowerCase()) && metricName !== dico.rotationSpeed.toLowerCase()): {
      metricUnit.unit = 'mm/s';
      metricUnit.decimals = 5;
      break;
    }

    case metricName === dico.anomalyScore?.toLowerCase(): {
      metricUnit.unit = '%';
      metricUnit.decimals = 2;
      break;
    }

    case metricName === dico.ultrasound?.toLowerCase(): {
      metricUnit.unit = 'dB';
      metricUnit.decimals = 2;
      break;
    }

    case metricName === dico.rotationSpeed?.toLowerCase(): {
      metricUnit.unit = 'rpm';
      metricUnit.decimals = 0;
      break;
    }

    case metricName.includes(dico.acceleration?.toLowerCase()): {
      metricUnit.unit = 'g';
      metricUnit.decimals = 3;
      break;
    }

    case metricName.includes('Kurtosis'): {
      metricUnit.unit = '';
      metricUnit.decimals = 2;
      break;
    }

    case metricName === dico.surfaceTemperature?.toLowerCase(): {
      metricUnit.unit = '°C';
      metricUnit.decimals = 1;
      break;
    }

    default: {
      metricUnit.unit = '';
      metricUnit.decimals = 2;
      break;
    }
  }
  return metricUnit;
};

export const getFormatedXYZQuery = (targets: any[], scopedVars: any, XYZmetric: string, macAddresses: string) => {
  let XYZFormatedQueries = [] as any[];
  const targetsXYZ = targets?.filter((target) => target.alias.includes(XYZmetric));
  if (!targetsXYZ.length) {
    return [];
  }
  for (const target of targetsXYZ) {
    const metricQuery = target.query.replace('^$beacons_selection$', `${macAddresses}`);
    const interpolatedVars = getTemplateSrv().replace(metricQuery, scopedVars);
    XYZFormatedQueries.push({ alias: target.alias, query: interpolatedVars });
  }
  return XYZFormatedQueries;
};

export const getDataPlot = (
  serie: any,
  metricName: string,
  dashboardName: string,
  yaxisPlot: string,
  colorLine: string,
  dico: TranslationProps,
  units: {
    temperature: TemperatureUnits;
    length: LengthUnits;
  },
  timeZone: string
) => {
  const isTemperatureMetric = metricName.includes(dico.surfaceTemperature);
  const isLengthMetric =
    metricName.includes(dico.velocity) ||
    (metricName.includes(dico.vibrationSeverity) && metricName !== dico.rotationSpeed);

  const metricUnit = () => {
    if (isTemperatureMetric && units.temperature !== TemperatureUnits.celsius) {
      return FAHRENHEIT;
    }
    if (isLengthMetric && units.length !== LengthUnits.meter) {
      return IN_S;
    }
    return getMetricUnit(metricName?.toLowerCase(), dico).unit;
  };

  const newDataPlot = {
    x: serie?.values?.map((val: any[]) => dateTimeFormatISO(val[0], { timeZone: timeZone })),
    y: serie?.values?.map((val: any[]) => {
      if (isTemperatureMetric && units.temperature !== TemperatureUnits.celsius) {
        return (val[1] * 9) / 5 + 32;
      }
      if (isLengthMetric && units.length !== LengthUnits.meter) {
        return val[1] / 25.4;
      }
      return val[1];
    }),
    type: 'scatter',
    mode: 'lines+markers',
    name: metricName,
    text: metricName,
    line: {
      width: 1,
      color: colorLine,
    },
    yaxis: yaxisPlot,
    marker: {
      size: 3,
    },
    legendgroup: serie?.tags?.device,
    legendgrouptitle: {
      text: dashboardName,
    },
    hoverinfo: 'name+x+text',
    hovertemplate: `%{y:.${getMetricUnit(metricName?.toLowerCase(), dico).decimals}f} ${metricUnit()}`,
  };

  return newDataPlot;
};

export const getDashboardIndex = (dashboards: any[], macAddress: string) => {
  return dashboards.findIndex((dash) => macAddress === dash.macAddress);
};

export const getDashboardName = (dashboards: any[], macAddress: string) => {
  let dashboardName = '';
  const indexDashboardName = dashboards.findIndex((dash) => dash.macAddress === macAddress);

  if (indexDashboardName !== -1) {
    dashboardName = dashboards[indexDashboardName].label;
  }
  return dashboardName;
};

export const getInterpolatedOrientation = (
  metricName: string,
  dashboardOrientation: { X: string; Y: string; Z: string }
) => {
  let interpolatedMetric = '';
  switch (true) {
    case metricName?.includes('X'):
      interpolatedMetric = metricName.replace('X', dashboardOrientation.X);
      break;
    case metricName?.includes('Y'):
      interpolatedMetric = metricName.replace('Y', dashboardOrientation.Y);
      break;
    case metricName?.includes('Z'):
      interpolatedMetric = metricName.replace('Z', dashboardOrientation.Z);
      break;
    default:
      break;
  }
  return interpolatedMetric;
};

export const getDashboardOrientation = (dashboards: DashboardsProps[], macAddress: string) => {
  const dashboardIndex = dashboards.findIndex((dash) => dash.macAddress === macAddress);
  let dashboardOrientation = { X: 'X', Y: 'Y', Z: 'Z' };

  if (dashboardIndex !== -1) {
    dashboardOrientation.X = dashboards[dashboardIndex].X;
    dashboardOrientation.Y = dashboards[dashboardIndex].Y;
    dashboardOrientation.Z = dashboards[dashboardIndex].Z;
  }
  return dashboardOrientation;
};

/** function used to display x, y, z color curves */
export const LightenDarkenColor = (hexColor: string, magnitude: number) => {
  hexColor = hexColor.replace(`#`, ``);
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16);
    let r = (decimalColor >> 16) + magnitude;
    r > 255 && (r = 255);
    r < 0 && (r = 0);
    let g = (decimalColor & 0x0000ff) + magnitude;
    g > 255 && (g = 255);
    g < 0 && (g = 0);
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
    b > 255 && (b = 255);
    b < 0 && (b = 0);
    return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
  } else {
    return hexColor;
  }
};

export const groupByMacAddress = (XYZResults: any[]) => {
  const macAddressResults = XYZResults.reduce((acc: GroupByMacAddressProps[], current, index) => {
    const macIndex = acc?.findIndex((serie: { macAddress: string }) => serie?.macAddress === current.tags?.device);
    if (index === 0 || macIndex === -1) {
      acc.push({
        macAddress: current.tags.device,
        queries: [{ name: current?.columns[1].slice(0, -1).trim(), query: [current] }],
      });
    } else {
      const queryIndex = acc[macIndex]?.queries?.findIndex(
        (query: { name: string }) => query?.name === current?.columns[1]?.slice(0, -1).trim()
      );
      if (queryIndex === -1) {
        acc[macIndex]?.queries.push({ name: current?.columns[1].slice(0, -1).trim(), query: [current] });
      } else {
        acc[macIndex]?.queries[queryIndex]?.query.push(current);
      }
    }
    return acc;
  }, []);
  return macAddressResults;
};

export const updateGraphTitle = (theme: GrafanaTheme2, metricName: string, position: number) => {
  return {
    text: metricName?.toUpperCase(),
    font: {
      size: 14,
      color: theme.colors.text.primary,
    },
    showarrow: false,
    align: 'center',
    x: 0,
    y: position,
    xref: 'paper',
    yref: 'paper',
  };
};

export const updatePlotLayout = (theme: GrafanaTheme2, layout: any) => {
  layout.xaxis.gridcolor = theme.isDark ? '#424345' : '#ececec';
  layout.yaxis.color = theme.isDark ? '#b3b3b3' : '#3c3c3c';
  layout.yaxis.gridcolor = theme.isDark ? '#424345' : '#ececec';
  layout.yaxis2.color = theme.isDark ? '#b3b3b3' : '#3c3c3c';
  layout.yaxis2.gridcolor = theme.isDark ? '#424345' : '#ececec';
  layout.plot_bgcolor = theme.isDark ? '#242425' : '#e5ecf6';
  layout.paper_bgcolor = theme.isDark ? '#1f1f20' : '#fbfcfe';
  layout.hoverlabel.bgcolor = theme.isDark ? '#2e2e2f' : '#f7f8fa';
  layout.hoverlabel.font.color = theme.colors.text.maxContrast;
  layout.legend.font.color = theme.colors.text.maxContrast;
  layout.legend.grouptitlefont.color = theme.colors.text.maxContrast;
};

export const makeSelectableOption = (array: any[]): SelectableValue[] => {
  return array?.map((tag: { term: string; count: number }) => ({
    label: tag.term,
    value: tag.term,
  }));
};

/**
 * Browse dashboards list and extract tags
 * @return  machines: [], otherTags: []
 */
export const getTagsFromDashboards = (dashboards: SelectableValue[]) => {
  const allDashboardTags = dashboards.reduce(
    (acc, current) => {
      const dashboardMachineTag = current.tags?.filter((tag: string) => tag.startsWith(PREFIX_MACHINE_FILTER));
      const dashboardOtherTags = current.tags?.filter(
        (tag: string) => !tag.startsWith(PREFIX_MACHINE_FILTER) && tag !== 'Machine'
      );

      if (dashboardMachineTag.length) {
        if (
          !acc.machines.length ||
          acc?.machines?.findIndex((machineTag: { label: string }) => machineTag?.label === dashboardMachineTag[0]) ===
            -1
        ) {
          acc.machines.push({ label: dashboardMachineTag[0], value: dashboardMachineTag[0] });
        }
      }

      if (dashboardOtherTags?.length) {
        for (const tag of dashboardOtherTags) {
          if (
            !acc.otherTags.length ||
            acc?.otherTags?.findIndex((machineTag: { label: string }) => machineTag?.label === tag) === -1
          ) {
            acc.otherTags.push({ label: tag, value: tag });
          }
        }
      }
      return acc;
    },
    { machines: [], otherTags: [] }
  );
  return allDashboardTags;
};

export const getOtherTagsOptions = (
  dashboardOptions: SelectableValue[],
  selectedTags: { machines: SelectableValue[] }
) => {
  const dashboardsFiltered = dashboardOptions.filter((dashbord) => {
    if (
      dashbord.tags.findIndex(
        (tag: string) => tag.replace(PREFIX_MACHINE_FILTER, '') === selectedTags.machines[0].label
      ) > -1
    ) {
      return true;
    }
    return false;
  });

  const otherTags = [] as any[];
  dashboardsFiltered.forEach((dash) => {
    for (const tag of dash.tags) {
      if (tag.includes(PREFIX_MACHINE_FILTER) || tag === 'Machine') {
        continue;
      }
      // Check if not in otherTags array
      if (otherTags.findIndex((otherTag) => otherTag.label === tag) === -1) {
        otherTags.push({ label: tag, value: tag });
      }
    }
  });

  return otherTags;
};

/** Get metric options and group together metrics that imply orientation (3axes) */
export const getMetricsOptions = (dico: TranslationProps, targets: any[]) => {
  const { vibrationsKurtosis, vibrationsPeakAcc, vibrationsRmsAcc, vibrationsRmsVel } = dico;
  let newMetrics = [] as any[];

  for (const target of targets) {
    switch (true) {
      case target?.alias?.includes(vibrationsKurtosis):
        newMetrics?.findIndex((metric) => metric?.label === vibrationsKurtosis) === -1 &&
          newMetrics.push({ label: vibrationsKurtosis, value: 'vib_kurtosis' });
        break;
      case target?.alias?.includes(vibrationsPeakAcc):
        newMetrics?.findIndex((metric) => metric?.label === vibrationsPeakAcc) === -1 &&
          newMetrics.push({ label: vibrationsPeakAcc, value: 'vib_peak' });
        break;
      case target?.alias?.includes(vibrationsRmsAcc):
        newMetrics?.findIndex((metric) => metric?.label === vibrationsRmsAcc) === -1 &&
          newMetrics.push({ label: vibrationsRmsAcc, value: 'vib_rms' });
        break;
      case target?.alias?.includes(vibrationsRmsVel):
        newMetrics?.findIndex((metric) => metric?.label === vibrationsRmsVel) === -1 &&
          newMetrics.push({ label: vibrationsRmsVel, value: 'vib_vel' });
        break;
      default:
        newMetrics.push({ label: target?.alias, value: target?.refId });
        break;
    }
  }
  const sortedMetrics = newMetrics.sort((a: { label: string }, b: { label: string }) =>
    a?.label?.localeCompare(b?.label)
  );
  return sortedMetrics;
};

/** Get dashboards from selected folders */
export const fromSelectedFolders = (dashboardOptions: SelectableValue[], selectedFolders: SelectableValue[]) => {
  const filteredDashboards = dashboardOptions?.filter(
    (dashboard) => selectedFolders.findIndex((folder) => folder.value === dashboard.folderId) !== -1
  );
  return filteredDashboards;
};

/** Get dashboards from selected other tags */
export const fromSelectedOtherTags = (dashboards: SelectableValue[], otherTags: SelectableValue[]) => {
  const dashboardsFiltered = dashboards?.filter((dashboard) => {
    for (const dashboardTag of dashboard?.tags) {
      if (otherTags?.findIndex((selectedTag) => selectedTag?.label === dashboardTag) !== -1) {
        return true;
      }
      continue;
    }
    return false;
  });
  return dashboardsFiltered;
};

/** Get dashboards from selected machine tags */
export const fromSelectedMachineTags = (dashboards: SelectableValue[], machineTags: SelectableValue[]) => {
  const dashboardFiltered = dashboards.filter((dashboard) => {
    for (const dashboardTag of dashboard.tags) {
      if (
        machineTags?.findIndex(
          (selectedTag) => selectedTag.label === dashboardTag.replace(PREFIX_MACHINE_FILTER, '')
        ) !== -1
      ) {
        return true;
      }
      continue;
    }
    return false;
  });
  return dashboardFiltered;
};

export const resetZoom = (plot: { xaxis: { autorange: boolean }; uirevision: any }) => {
  const rand = () => Math.random();
  plot.xaxis.autorange = true;
  plot.uirevision = rand();
};

export const interpolateTimeFilter = (timeRange: TimeRange, query: string, timeZone: string) => {
  const from = timeRange.from.valueOf().toString();
  const to = timeRange.to.valueOf().toString();
  const timeFilter = `time >= ${from}ms and time <= ${to}ms`;
  const queryTosend = query.replace(/\$timeFilter/g, timeFilter);
  return `${queryTosend} tz('${timeZone}')`;
};
