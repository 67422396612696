import React from 'react';
import { useAppState } from 'app-context/AppStateContext';
import { SelectableValue } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';
import { API_SEARCH_FOLDERS } from 'utils/api_url';

export const useFolders = () => {
  const { state, dispatch } = useAppState();
  const { folderOptions } = state;
  const [options, setOptions] = React.useState<SelectableValue[]>([]);

  const getFolders = async () => {
    await getBackendSrv()
      .get(API_SEARCH_FOLDERS)
      .then(async (resultDashboards) => {
        const folders = resultDashboards?.map((result: { title: string; id: number }) => ({
          label: result.title,
          value: result.id,
        }));
        folders.unshift({ label: 'General', value: 0 });
        setOptions(folders);
        dispatch({ type: 'SET_FOLDERS_OPTIONS', payload: folders });
      });
  };

  React.useEffect(() => {
    if (!folderOptions?.length) {
      getFolders();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return options;
};
