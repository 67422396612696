import React, { useEffect } from 'react';
import { useAppState } from 'app-context/AppStateContext';
import { fetchTags } from 'utils/fetchTags';
import { FiltersMultiSelectTags } from './';
import { TagType } from 'app-context/types';

interface FiltersTagsListProps {}

export const FiltersTagsList: React.FunctionComponent<FiltersTagsListProps> = () => {
  const { state, dispatch } = useAppState();
  const { tagsOptions, dico } = state;

  const getAllTags = async () => {
    const { machineTags, otherTags } = await fetchTags();
    dispatch({
      type: 'SET_TAGS_OPTIONS',
      payload: { machines: machineTags, otherTags: otherTags },
    });
  };

  useEffect(() => {
    if (!tagsOptions?.machines?.length && !tagsOptions?.otherTags?.length) {
      getAllTags();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {/* Machine tags */}
      <FiltersMultiSelectTags
        iconName={'cog'}
        id={TagType.machine}
        options={tagsOptions.machines}
        placeholder={dico.selectMachine}
        selectLabel={dico.filterByMachine}
      />
      {/* Other tags */}
      <FiltersMultiSelectTags
        iconName={'tag-alt'}
        id={TagType.other}
        options={tagsOptions.otherTags}
        placeholder={dico.selectTag}
        selectLabel={dico.filterByTag}
      />
    </>
  );
};
