import React, { useState } from 'react';
import { css } from '@emotion/css';
import { useStyles2, Icon } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { useAppState } from 'app-context/AppStateContext';
import { FiltersSelectionSummary, FiltersFoldableSection } from './';

interface FiltersContainerProps {
  data: any;
}

export const FiltersContainer: React.FunctionComponent<FiltersContainerProps> = ({ data }) => {
  const { state } = useAppState();
  const { selectedDashboards, selectedMetrics, dico } = state;
  const [isFiltersOpen, setIsFilterOpen] = useState(false);
  const styles = useStyles2(getStyles);

  /** Translation */
  const TITLE_DASHBOARDS_METRICS = dico.dashboardAndMetrics?.toUpperCase();

  const OPEN_SECTION_ICON = isFiltersOpen ? (
    <Icon name={'angle-down'} size={'xl'} />
  ) : (
    <Icon name={'angle-right'} size={'xl'} />
  );

  return (
    <div className={styles.mainContainer}>
      {/* Horizontal bar with title and selected items */}
      <div className={styles.horizontalBar}>
        <div onClick={() => setIsFilterOpen(!isFiltersOpen)} className={styles.clickableTitle}>
          {OPEN_SECTION_ICON} {TITLE_DASHBOARDS_METRICS}
        </div>
        {!isFiltersOpen && (
          <FiltersSelectionSummary
            selectedMetrics={selectedMetrics}
            selectedDashboards={selectedDashboards}
            dico={dico}
          />
        )}
      </div>
      {/* Filters container */}
      {isFiltersOpen && <FiltersFoldableSection data={data} />}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    mainContainer: css`
      background-color: ${theme.isDark ? '#1f1f20' : '#fbfcfe'};
      display: flex;
      flex-wrap: wrap;
      padding: 8px 12px;
      width: 100%;
      border: 12px solid ${theme.isDark ? '#1f1f20' : theme.colors.background.canvas};
    `,
    horizontalBar: css`
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    `,
    clickableTitle: css`
      cursor: pointer;
      padding: 8px;
      font-size: 18px;
      color: ${theme.colors.text.primary};
      font-weight: 500;
    `,
    filters: css`
      display: flex;
      flex-direction: column;
      background-color: ${theme.colors.background.secondary};
      flex: 2;
      padding: 16px;
    `,
  };
};
