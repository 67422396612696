import { API_TAGS_LIST } from './api_url';
import { getBackendSrv } from '@grafana/runtime';
import { makeSelectableOption } from './helpers';
import { PREFIX_MACHINE_FILTER } from './constants';

/** Get all tags */
export const fetchTags = async () => {
  try {
    const results = await getBackendSrv().get(API_TAGS_LIST);

    const machineFilters = results
      .filter((result: { term: string }) => result.term?.includes(PREFIX_MACHINE_FILTER))
      .map((result: { term: string; count: number }) => {
        const splitTag = result.term.split('_');
        if (splitTag.length) {
          return { term: splitTag[1], count: result.count };
        }
        return result;
      });

    const otherTags = results.filter(
      (result: { term: string }) =>
        !result.term?.includes(PREFIX_MACHINE_FILTER) &&
        result.term.toLowerCase() !== 'summary' && // Overview dashboard
        result.term.toLowerCase() !== 'comparison' && // Comparison dashboard
        result.term.toLowerCase() !== 'machine' && // Machine dashboards
        result.term.toLowerCase() !== 'main' // Home dashboard
    );

    const formatedTags = makeSelectableOption(otherTags);
    const formatedMachines = makeSelectableOption(machineFilters);

    return {
      machineTags: formatedMachines,
      otherTags: formatedTags,
    };
  } catch (err: any) {
    console.error(err.message);
    return { machineTags: [], otherTags: [] };
  }
};
