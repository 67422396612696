import React from 'react';
import { css } from '@emotion/css';
import { MultiSelect, useStyles2, Icon } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { useFolders } from 'hooks/useFoldersOptions';

interface FoldersListProps {}

export const FiltersFoldersList: React.FunctionComponent<FoldersListProps> = () => {
  const { state, dispatch } = useAppState();
  const { selectedFolders, dico, folderOptions } = state;
  useFolders();
  const styles = useStyles2(getStyles);

  const onFolderChange = (e: any) => {
    dispatch({ type: 'SET_SELECTED_FOLDERS', payload: e });
    dispatch({ type: 'SET_SELECTED_TAGS', payload: { machines: [], otherTags: [] } });
    dispatch({ type: 'SET_SELECTED_DASHBOARDS', payload: [] });
  };

  return (
    <div className={styles.mainContainer}>
      <div>{dico.filterByFolder}</div>
      <MultiSelect
        options={folderOptions}
        value={selectedFolders}
        onChange={(v) => onFolderChange(v)}
        maxVisibleValues={5}
        isClearable
        isSearchable
        placeholder={dico.selectFolder}
        maxMenuHeight={200}
        prefix={<Icon name={'folder-open'} />}
      />
    </div>
  );
};

export const getStyles = () => {
  return {
    mainContainer: css`
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      flex: 1;
      min-width: 277px;
      width: 100%;
      margin: 2px;
    `,
  };
};
