import * as React from 'react';
import { PanelProps } from '@grafana/data';
import { AppStateProvider } from 'app-context/AppStateContext';
import { MainContainer } from 'MainContainer';

interface Props extends PanelProps {}

export const ComparisonDashboard: React.FunctionComponent<Props> = ({ timeRange, data, width, timeZone }) => {
  return (
    <AppStateProvider>
      <MainContainer data={data} timeRange={timeRange} width={width} timeZone={timeZone} />
    </AppStateProvider>
  );
};
