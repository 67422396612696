import { GrafanaTheme2, TimeRange, SelectableValue } from '@grafana/data';
import { TranslationProps } from './translations';
import { Action } from './actions';
export interface AppStateContextProps {
  state: AppState;
  dispatch: React.Dispatch<Action>;
}

export interface AppState {
  dashboardOptions: SelectableValue[];
  dico: TranslationProps;
  folderOptions: SelectableValue[];
  influx: any;
  mysql: any;
  lang: string;
  metricsOptions: SelectableValue[];
  metricsToDisplay: SelectableValue[];
  panelData: any;
  selectedDashboards: DashboardsProps[];
  selectedFolders: any[];
  selectedMetrics: any[];
  selectedTags: { machines: any[]; otherTags: any[] };
  tagsOptions: { machines: any[]; otherTags: any[] };
  theme: GrafanaTheme2;
  timeRange: TimeRange;
  timeZone: string;
  user: {
    isGrafanaAdmin: boolean;
    orgName: string;
    orgRole: string;
    orgId: number;
    timeZone: string;
  };
}

export interface DashboardItem {
  name: string;
  id: number;
  folderId: number;
  folderTitle: string;
  uid: string;
  dashboards?: string;
  tags: string[];
  profile: string;
  macAddress: string;
}

export interface DashboardsProps {
  title: string;
  id: number;
  folderId: number;
  folderTitle: string;
  uid: string;
  tags: string[];
  macAddress: string;
  X: string;
  Y: string;
  Z: string;
  description: string;
}

export interface DataPlotProps {
  hoverinfo: string;
  hovertemplate: string;
  legendgroup: string;
  legendgrouptitle: { text: string };
  line: { width: number; color: string };
  marker: { size: number };
  mode: string;
  name: string;
  text: string;
  type: string;
  x: number[];
  y: number[];
  yaxis: string;
}

export enum UnitTypes {
  temperature = 'temperature',
  length = 'length',
}

export enum TemperatureUnits {
  celsius = 'celsius',
  fahrenheit = 'fahrenheit',
}

export enum LengthUnits {
  meter = 'meter',
  inch = 'inch',
}

export interface ResultsQuery {
  columns: string[];
  name: string;
  tags: {
    device: string;
  };
  values: any[];
}

export interface QueryProps {
  name: string;
  query: ResultsQuery[];
}

export interface GroupByMacAddressProps {
  macAddress: string;
  queries: QueryProps[];
}

export enum TagType {
  machine = 'machine',
  other = 'other',
}
