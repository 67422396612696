import * as React from 'react';
import { config } from '@grafana/runtime';
import { useTheme2 } from '@grafana/ui';
import { AppState } from './types';
import { TimeRange } from '@grafana/data';
import { TranslationProps } from './translations';
import { getTemplateCurrentText } from 'utils/helpers';

/**
 * Initialize default state and pass it to the main app component
 */

export const withData = (
  WrappedComponent: React.ComponentType<React.PropsWithChildren<{ initialState: AppState }>>
) => {
  // eslint-disable-next-line react/display-name
  return ({ children }: React.PropsWithChildren<{}>) => {
    const userPath = config.bootData.user;
    const langTemplate = getTemplateCurrentText('lang_selection');

    const currentTheme = useTheme2();
    let langSelect = '';

    if (!langTemplate) {
      langSelect = 'EN';
    } else {
      langSelect = langTemplate as string;
    }

    const defaultState = {
      dashboardOptions: [],
      dico: {} as TranslationProps,
      folderOptions: [],
      influx: [],
      mysql: [],
      lang: langSelect,
      metricsOptions: [],
      metricsToDisplay: [],
      panelData: [],
      selectedDashboards: [],
      selectedFolders: [],
      selectedMetrics: [],
      selectedTags: { machines: [], otherTags: [] },
      tagsOptions: { machines: [], otherTags: [] },
      theme: currentTheme,
      timeRange: {} as TimeRange,
      timeZone: '',
      user: {
        isGrafanaAdmin: userPath.isGrafanaAdmin,
        orgName: userPath.orgName,
        orgId: userPath.orgId,
        orgRole: userPath.orgRole,
        timeZone: userPath.timezone,
      },
    };

    const [initialState] = React.useState<AppState>(defaultState);
    return <WrappedComponent initialState={initialState}>{children}</WrappedComponent>;
  };
};
