import React from 'react';
import { Button } from '@grafana/ui';
import { dateTimeFormatISO } from '@grafana/data';
import { DataPlotProps } from 'app-context/types';
import { css } from '@emotion/css';
import { saveAs } from 'file-saver';

interface ButtonsForExportProps {
  dataPlot: DataPlotProps[];
}

export const ButtonsCSVExport: React.FunctionComponent<ButtonsForExportProps> = ({ dataPlot }) => {
  const fileTitle = `Comparison-${dateTimeFormatISO(new Date())}`;

  const getMetricName = (metricName: string) => (metricName.endsWith('X') ? metricName?.slice(0, -1) : metricName);

  const exportData = (dataToSend: DataPlotProps[]) => {
    if (!dataToSend.length) {
      return;
    }
    let csvData: any[] = [];
    for (const [index, data] of dataToSend.entries()) {
      csvData.push(['Time', `Y${index + 1} (${data.name}-${data.legendgrouptitle?.text})`]);
      data.x.forEach((x, xIndex) => {
        csvData.push([dateTimeFormatISO(x), data.y[xIndex]]);
      });
    }

    let csvContent = '';
    csvData.forEach((infoArray, index) => {
      let dataString = infoArray.join(',');
      csvContent += index <= csvData.length ? dataString + '\n' : dataString;
    });

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `${fileTitle}.csv`);
  };

  const getY1data = dataPlot.filter((data) => data.yaxis === 'y');
  const getY2data = dataPlot.filter((data) => data.yaxis === 'y2');

  return (
    <div
      className={css`
        display: flex;
        padding: 18px;
      `}
    >
      {getY1data.length !== 0 && (
        <Button
          variant={'secondary'}
          size={'sm'}
          onClick={() => exportData(getY1data)}
          style={{ marginRight: '6px' }}
          icon={'download-alt'}
        >
          CSV {getMetricName(getY1data[0]?.name)}
        </Button>
      )}
      {getY2data.length !== 0 && (
        <Button variant={'secondary'} size={'sm'} onClick={() => exportData(getY2data)} icon={'download-alt'}>
          CSV {getMetricName(getY2data[0]?.name)}
        </Button>
      )}
    </div>
  );
};
