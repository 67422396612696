import React, { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import { useStyles2, MultiSelect } from '@grafana/ui';
import { fetchDashboardsNames } from 'utils/fetchDashboardsNames';
import { useAppState } from 'app-context/AppStateContext';
import { fromSelectedFolders, fromSelectedMachineTags, fromSelectedOtherTags } from 'utils/helpers';
import { GrafanaTheme2 } from '@grafana/data';

interface FiltersDashboardsListProps {}

const LIMIT_DASHBOARD_SELECTION = 6;

export const FiltersDashboardsList: React.FunctionComponent<FiltersDashboardsListProps> = ({}) => {
  const { state, dispatch } = useAppState();
  const { selectedDashboards, selectedFolders, dashboardOptions, selectedTags, dico } = state;
  const { valve, rotatingProfile, nonRotatingProfile, profile, selectDashboards, dashboards, mixed } = dico;
  const [dashboardList, setDashboardList] = useState<any[]>();
  const styles = useStyles2(getStyles);

  const getDashboards = async () => {
    const dashboardsList = await fetchDashboardsNames();
    setDashboardList(dashboardsList);
    dispatch({ type: 'SET_DASHBOARDS_OPTIONS', payload: dashboardsList });
  };

  const onSelectChange = async (value: any) => {
    if (!value) {
      dispatch({ type: 'SET_SELECTED_DASHBOARDS', payload: [] });
      return;
    }

    if (value.length > LIMIT_DASHBOARD_SELECTION) {
      return;
    }

    dispatch({ type: 'SET_SELECTED_DASHBOARDS', payload: value });
  };

  useEffect(() => {
    if (!dashboardOptions?.length) {
      getDashboards();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedFolders?.length) {
      const newDashboardList = fromSelectedFolders(dashboardOptions, selectedFolders);
      setDashboardList(newDashboardList);

      if (selectedDashboards?.length) {
        const newDashboardsValue = fromSelectedFolders(selectedDashboards, selectedFolders);
        dispatch({ type: 'SET_SELECTED_DASHBOARDS', payload: newDashboardsValue as any[] });
      }
    } else {
      setDashboardList(dashboardOptions);
    }
  }, [selectedFolders]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (dashboardOptions.length) {
      // Filter by machine tag
      if (selectedTags?.machines?.length) {
        const dashboardsFiltered = fromSelectedMachineTags(dashboardOptions, selectedTags?.machines);
        // Filter by "other" tags
        if (selectedTags.otherTags.length) {
          const dashWithSelectedOtherTags = fromSelectedOtherTags(dashboardsFiltered, selectedTags.otherTags);
          setDashboardList(dashWithSelectedOtherTags);
        } else {
          setDashboardList(dashboardsFiltered);
        }
      } else if (selectedFolders.length) {
        // Filter by folder
        const newDashboardList = fromSelectedFolders(dashboardOptions, selectedFolders);
        if (selectedTags.otherTags.length) {
          const dashWithSelectedOtherTags = fromSelectedOtherTags(newDashboardList, selectedTags?.otherTags);
          setDashboardList(dashWithSelectedOtherTags);
        } else {
          setDashboardList(newDashboardList);
        }
      } else if (selectedTags.otherTags.length) {
        // Filter by "other" tags
        const dashWithSelectedOtherTags = fromSelectedOtherTags(dashboardOptions, selectedTags?.otherTags);
        setDashboardList(dashWithSelectedOtherTags);
      } else {
        setDashboardList(dashboardOptions);
      }
    }
  }, [selectedTags]); // eslint-disable-line react-hooks/exhaustive-deps

  const profileSelected = React.useMemo(() => {
    let currentProfile = '';
    if (selectedDashboards.length !== 0) {
      switch (true) {
        case selectedDashboards.every((selected) => selected.description === valve):
          currentProfile = `${profile}: ${valve}`;
          break;
        case selectedDashboards.every(
          (selected) => selected.description === nonRotatingProfile || selected.description === rotatingProfile
        ):
          currentProfile = `${profile}: ${rotatingProfile}/${nonRotatingProfile}`;
          break;

        default:
          currentProfile = `${profile}: ${mixed}`;
          break;
      }
    }
    return currentProfile;
  }, [selectedDashboards]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.mainContainer}>
      <div className={styles.title}>
        <div>
          {dashboards?.toUpperCase()} {selectedDashboards?.length} / {LIMIT_DASHBOARD_SELECTION}
        </div>
        {profileSelected && <div className={styles.profile}>{profileSelected}</div>}
      </div>
      <MultiSelect
        options={dashboardList}
        value={selectedDashboards}
        onChange={(v) => onSelectChange(v)}
        maxVisibleValues={LIMIT_DASHBOARD_SELECTION}
        isClearable
        isSearchable
        placeholder={selectDashboards}
        maxMenuHeight={200}
      />
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    mainContainer: css`
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: flex-start;
      margin: 2px;
      min-width: 277px;
      padding: 4px;
      width: 100%;
    `,
    title: css`
      color: ${theme.colors.text.primary};
      font-weight: 500;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
    `,
    profile: css`
      font-size: 11px;
      background-color: ${theme.colors.primary.shade};
      padding: 0 6px;
      color: white;
      margin-left: 8px;
      border-radius: 4px;
    `,
  };
};
