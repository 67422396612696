import React from 'react';
import { css } from '@emotion/css';
import { useStyles2, Icon } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { GrafanaTheme2 } from '@grafana/data';

export const Header = () => {
  const { state } = useAppState();
  const { dico, theme } = state;

  const styles = useStyles2(getStyles);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.titleContainer}>
        <Icon name={'arrow-random'} size={'xxxl'} style={{ marginRight: '15px', color: theme.colors.text.primary }} />
        <div>
          <div className={styles.mainTitle}>{dico.dashboardComparison}</div>
          <div className={styles.subtitle}>{dico.compareDashboardsMetrics}</div>
        </div>
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    mainContainer: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      background-color: ${theme.isDark ? '#1f1f20' : theme.colors.background.canvas};
    `,
    titleContainer: css`
      display: flex;
      align-items: center;
    `,
    mainTitle: css`
      font-size: 26px;
      font-weight: 500;
      color: ${theme.colors.text.primary};
    `,
    subtitle: css`
      color: ${theme.colors.text.secondary};
    `,
    userSection: css`
      color: ${theme.colors.text.secondary};
      font-weight: 500;
      font-size: 16px;
      margin-right: 12px;
      display: flex;
    `,
    iconstyle: css`
      padding: 4px;
      margin-right: 5px;
    `,
  };
};
