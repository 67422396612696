import React, { useEffect } from 'react';
import { useAppState } from 'app-context/AppStateContext';
import { PanelData, TimeRange } from '@grafana/data';
import { getDataSourceSrv, getBackendSrv } from '@grafana/runtime';
import { PlotsSection } from 'PlotsSection';
import { FiltersContainer } from 'components/filters/FiltersContainer';
import { Header } from 'components/Header';
import { TranslationProps } from 'app-context/translations';
import { getTemplateCurrentText } from 'utils/helpers';

interface MainContainerProps {
  data: PanelData;
  timeRange: TimeRange;
  width: number;
  timeZone: string;
}

export const MainContainer: React.FunctionComponent<MainContainerProps> = ({ data, timeRange, width, timeZone }) => {
  const { dispatch, state } = useAppState();
  const { lang } = state;

  const getDatasources = async () => {
    const influxDatasourceName = getTemplateCurrentText('influx');
    const influxDs = await getDataSourceSrv()
      .get(influxDatasourceName)
      .catch((err: any) => console.log(err));

    const mysqlDatasourceName = getTemplateCurrentText('mysql');
    const mysqlDs = await getDataSourceSrv()
      .get(mysqlDatasourceName)
      .catch((err: any) => console.log(err));

    dispatch({
      type: 'SET_DATASOURCES',
      payload: {
        influx: influxDs,
        mysql: mysqlDs,
      },
    });
  };

  const getDictionary = () => {
    getBackendSrv()
      .get(`/public/asystom/asystom-dictionaries/comparison-dashboard/${lang}.json`)
      .then((response) => {
        const translation = response as TranslationProps;
        dispatch({ type: 'SET_DICO', payload: translation });
      })
      .catch((err: any) => console.log(err));
  };

  useEffect(() => {
    getDatasources();
    getDictionary();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data) {
      dispatch({ type: 'SET_PANEL_DATA', payload: data });
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (timeRange) {
      dispatch({ type: 'GET_TIME_RANGE', payload: timeRange });
    }
  }, [timeRange]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (timeZone) {
      dispatch({ type: 'SET_TIMEZONE', payload: timeZone });
    }
  }, [timeZone]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ height: '100%', overflow: 'auto' }}>
      <Header />
      <FiltersContainer data={data} />
      <PlotsSection />
    </div>
  );
};
