import { AppState } from './types';
import { Action } from './actions';

/**
 * Contains all the state actions
 */

export const appStateReducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case 'SET_PANEL_DATA': {
      return {
        ...state,
        panelData: action.payload,
      };
    }

    case 'SET_DICO': {
      return {
        ...state,
        dico: action.payload,
      };
    }

    case 'GET_TIME_RANGE': {
      return {
        ...state,
        timeRange: action.payload,
      };
    }

    case 'SET_TIMEZONE': {
      return {
        ...state,
        timeZone: action.payload,
      };
    }

    case 'SET_DATASOURCES': {
      return {
        ...state,
        influx: action.payload.influx,
        mysql: action.payload.mysql,
      };
    }

    case 'SET_SELECTED_DASHBOARDS': {
      return {
        ...state,
        selectedDashboards: action.payload,
      };
    }

    case 'SET_SELECTED_METRICS': {
      return {
        ...state,
        selectedMetrics: action.payload,
      };
    }

    case 'SET_FOLDERS_OPTIONS': {
      return {
        ...state,
        folderOptions: action.payload,
      };
    }

    case 'SET_DASHBOARDS_OPTIONS': {
      return {
        ...state,
        dashboardOptions: action.payload,
      };
    }

    case 'SET_TAGS_OPTIONS': {
      return {
        ...state,
        tagsOptions: {
          machines: action.payload.machines,
          otherTags: action.payload.otherTags,
        },
      };
    }

    case 'SET_METRICS_OPTIONS': {
      return {
        ...state,
        metricsOptions: action.payload,
      };
    }

    case 'SET_METRICS_TO_DISPLAY': {
      return {
        ...state,
        metricsToDisplay: action.payload,
      };
    }

    case 'SET_SELECTED_FOLDERS': {
      return {
        ...state,
        selectedFolders: action.payload,
      };
    }

    case 'SET_SELECTED_TAGS': {
      return {
        ...state,
        selectedTags: {
          machines: action.payload.machines,
          otherTags: action.payload.otherTags,
        },
      };
    }

    default: {
      return state;
    }
  }
};
